html {
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif, Arial, Helvetica;
}
body {
  font-family: 'Poppins', sans-serif, Arial, Helvetica;
  margin: 0;
}

a {
  text-decoration: none;
  color: black;
}
* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'), url(./assets/fonts/Poppins-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'UTM Avo';
  font-style: normal;
  font-weight: normal;
  src: local('UTM Avo'), url('./assets/fonts/utm-avo.woff') format('woff');
}
  

@font-face {
  font-family: 'UTMAvoBold';
  font-style: normal;
  font-weight: normal;
  src: local('UTMAvoBold'), url('./assets/fonts/utm-avobold.woff') format('woff');
}

$primary: #0da9ef;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
